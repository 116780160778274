import * as React from "react"
import scrollTo from "../utils/scrollTo"
import Preorder from "../components/preorder"

const Introduction = ({content, link, button, buttonText, preorder}) => (
  <div className='introduction p40 m-pt0'> 
    <div className='w-70 m-100'>
      <div className='uppercase max-750 white-space mb40' dangerouslySetInnerHTML={{ __html:content }} />
      {(link ? <a className='button  m0' target='_blank' href={link}>{(button)}</a>:'')}
      {(preorder ? 
        <div className='max-500 mt40 pt20'>
          <p className='strong uppercase'>{preorder}</p>
          <Preorder />
        </div>:''
      )}
    </div>
  </div>
)

export default Introduction
