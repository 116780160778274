import * as React from "react"
import scrollTo from "../utils/scrollTo"

const Title = ({title, link, buttonText, price, button}) => (
  <div className={'title p40'}>
    <div className='flex'>
      <h2 className='h1 m0'>{title}</h2>
      <div className='mla aktiv m-hide'>
        {(link ?
          <a className='button  m0' target='_blank' href={link}>{(button ? button : buttonText)}</a> :
          <p className='button  m0' role='presentation' onClick={() => scrollTo('#form')}>{ buttonText }</p>
        )}        
      </div>
    </div>
    <div className='flex mt20 bb1 pb40 m-pb20'>
      <p className='m0 h2'>{price}</p>
      <p className='m0 uppercase ml20 mta mb5'>exc govt charges</p>
    </div>
  </div>
)

export default Title
